import { useState, useRef, MouseEvent, TouchEvent, useEffect } from 'react';
import * as Icons from 'solar-icon-set';

interface VisualSliderProps {
  beforeImage: string;
  afterImage: string;
  height?: string;
}

const VisualSlider = ({ beforeImage, afterImage, height = '400px' }: VisualSliderProps) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [imagesLoaded, setImagesLoaded] = useState({ before: false, after: false });
  const sliderRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
 
  // Add image preloading
  const preloadImages = () => {
    const beforeImg = new Image();
    const afterImg = new Image();

    beforeImg.onload = () => setImagesLoaded(prev => ({ ...prev, before: true }));
    afterImg.onload = () => setImagesLoaded(prev => ({ ...prev, after: true }));

    beforeImg.src = beforeImage;
    afterImg.src = afterImage;
  };

  useEffect(() => {
    preloadImages();
  }, [beforeImage, afterImage]);

  console.log('Before Image:', beforeImage);
  console.log('After Image:', afterImage);

  const handleMove = (clientX: number) => {
    if (!isDragging.current || !sliderRef.current) return;
    
    const rect = sliderRef.current.getBoundingClientRect();
    const position = ((clientX - rect.left) / rect.width) * 100;
    setSliderPosition(Math.min(Math.max(position, 0), 100));
  };

  const handleMouseDown = () => {
    isDragging.current = true;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e: MouseEvent) => {
    handleMove(e.clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    handleMove(e.touches[0].clientX);
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* Top Corner Labels */}
      <div
        style={{
          position: 'absolute',
          left: '16px',
          top: '-31px',
          backgroundColor: '#FFF3DB', // pf-daylight-T3
          padding: '4px 12px',
          borderRadius: '4px',
          border: '2px solid #FFBA31', // pf-daylight
          color: '#F8A90C', // pf-daylight-S1
          fontSize: '14px',
          fontWeight: 500,
          zIndex: 1
        }}
      >
        Their Current YouTube
      </div>
      <div
        style={{
          position: 'absolute',
          right: '16px',
          top: '-31px',
          backgroundColor: '#FFF3DB', // pf-daylight-T3
          padding: '4px 12px',
          borderRadius: '4px',
          border: '2px solid #FFBA31', // pf-daylight
          color: '#F8A90C', // pf-daylight-S1
          fontSize: '14px',
          fontWeight: 500,
          zIndex: 1
        }}
      >
        Their YouTube with Pathfinder
      </div>

      {/* Main Slider Container with Border */}
      <div
        ref={sliderRef}
        style={{
          position: 'relative',
          width: `${parseInt(height) * 16/10.88}px`,
          height: height,
          overflow: 'hidden',
          userSelect: 'none',
          border: '2px solid #FFBA31', // pf-daylight
          borderRadius: '8px',
          padding: '16px',
          backgroundColor: '#fff'
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        onTouchMove={handleTouchMove}
      >
        {/* Loading State */}
        {(!imagesLoaded.before || !imagesLoaded.after) && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#666666'
          }}>
            Loading images...
          </div>
        )}

        {/* Before Image (Full size, behind) */}
        <img 
          src={beforeImage}
          alt="Before"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: imagesLoaded.before ? 1 : 0,
          }}
        />
        
        {/* After Image (Full size, clipped) */}
        <img 
          src={afterImage}
          alt="After"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: imagesLoaded.after ? 1 : 0,
            clipPath: `inset(0 0 0 ${sliderPosition}%)`,
          }}
        />

        {/* Slider Divider Line */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: `${sliderPosition}%`,
            width: '3px',
            height: '100%',
            backgroundColor: '#FFDF9E',
            boxShadow: '0 0 10px rgba(255, 186, 49, 0.3)',
            transform: 'translateX(-50%)',
            zIndex: 5
          }}
        />

        {/* Slider Handle */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: `${sliderPosition}%`,
            transform: 'translate(-50%, -50%)',
            width: '48px',
            height: '48px',
            backgroundColor: '#FFDF9E',
            borderRadius: '50%',
            cursor: 'ew-resize',
            boxShadow: '0 3px 8px rgba(255, 186, 49, 0.4)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            transition: 'transform 0.2s ease'
          }}
        >
            <Icons.AltArrowLeft style={{marginRight: '-4px'}} />
            <Icons.AltArrowRight style={{marginLeft: '-4px'}} />
        </div>
      </div>
    </div>
  );
};

export default VisualSlider;
