import React from 'react';
import Tooltip from '../tooltip/Tooltip';
import Button from '../button/Button';
import '../../../../styles/responsive-text.css';

const ToggleButtons: React.FC<{
    active: boolean;
    onToggle: () => void;
    label: string;
    color?: string;
}> = ({ active, onToggle, label, color }) => (
    <button
        onClick={onToggle}
        className={`px-4 md:px-8 py-2 md:py-4 rounded-sm md:rounded-md focus:outline-none transition-colors duration-200 ${active
            ? `bg-${color || 'white'}`
            : "bg-transparent text-black"
            }`}
    >
        <p className={`responsive-text ${color && active ? 'text-white' : 'text-black'}`}>{label}</p>
    </button>
);

type WordToggleProps = {
    leftSelected: boolean,
    leftLabel: string,
    rightLabel: string,
    colorOn?: string,
    colorOff?: string,
    border?: string,
    onLeft: () => void,
    onRight: () => void
}

const WordToggle: React.FC<WordToggleProps> = ({ leftSelected, leftLabel, rightLabel, colorOn, colorOff, border, onLeft, onRight }) => {

    return (
        <div className={`flex space-x-2 p-2 `}>
            <Tooltip position="bottom" content={!leftSelected ? rightLabel : leftLabel}>
                <div className={`p-1 flex space-x-1 justify-between ${colorOff ? `bg-${colorOff}` : 'bg-gray-100'} ${border ? `${border}` : ''} rounded-md`}>
                    <ToggleButtons
                        active={leftSelected}
                        color={colorOn}
                        onToggle={() => {
                            onLeft();
                        }}
                        label={leftLabel}
                    />
                    <ToggleButtons
                        active={!leftSelected}
                        color={colorOn}
                        onToggle={() => {
                            onRight();
                        }}
                        label={rightLabel}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default WordToggle;