import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import Benefits from '../../sections/Benefits';
import CTA from '../../sections/CTA';
import Demo from '../../sections/Demo';
import FAQs from '../../sections/FAQs';
import Footer from '../../sections/Footer';
import Header from '../../components/Header/Header';
import Hero from '../../sections/Hero';
import HowItWorks from '../../sections/HowItWorks';
import Press from '../../sections/Press';
import Testimonials from '../../sections/Testimonials';
import MobileHeader from '../../components/Header/MobileHeader';
import Projects from '../../sections/Projects';
import heroBg from '../../assets/images/bg-hero.png';
import Button from '../../utils/components/grapes/atoms/button/Button';
import SlopImage from '../../assets/images/Slop YouTube.png';
import ExcellenceImage from '../../assets/images/Excellence YouTube.png';

interface MainProps {
    title?: string;
    description?: string;
    imageBoolean?: boolean;
}

const Main: React.FC<MainProps> = ({ title, description, imageBoolean }) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Landing Page View', {
                endpoint: window.location.pathname.substring(1),
                title,
                description
            });
        }
    }, [title, description]);

    return (
        <div className="max-w-screen h-full flex flex-col">
            {/* <img src={heroBg} alt="hero-bg" className="absolute top-0 left-0 w-screen h-screen object-cover z-[-1]" /> */}
            <div className="max-w-screen w-full sticky top-0 z-50">
                <div className="max-w-screen w-full hidden md:block">
                    <Header button={title && description && (
                        <Button variant="primary" size="sm" onClick={() => setShowModal(true)}><p className="text-sm">Try it for free</p></Button>
                    )} />
                </div>
                <div className="max-w-screen w-full block md:hidden">
                    {title && description ? (
                        <Header button={
                            <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>Try it for free</Button>
                        } />
                    ) : (
                        <MobileHeader />
                    )}
                </div>
            </div>
            <div className="">
                <Hero images={imageBoolean ? [SlopImage, ExcellenceImage] : null} title={title} description={description} showModal={showModal} setShowModal={setShowModal} />
            </div>
            <Footer />
        </div>
    );
};

export default Main;


/**
<div className="sticky top-0 z-50">
<div className="hidden md:block">
    <Header button={title && description && (
        <Button variant="primary" size="md" onClick={() => setShowModal(true)}>Try it for free</Button>
    )} />
</div>
<div className="block md:hidden">
    {title && description ? (
        <Header button={
            <Button variant="primary" size="md" onClick={() => setShowModal(true)}>Try it for free</Button>
        } />
    ) : (
        <MobileHeader />
    )}
</div>
</div>
<div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 w-full">
<img src={heroBg} alt="hero-bg" className="absolute top-0 left-0  bg-red-500 h-[100vh] object-cover" />
<Hero images={imageBoolean ? [SlopImage, ExcellenceImage] : null} title={title} description={description} showModal={showModal} setShowModal={setShowModal} />
 <Projects />
 <Benefits />
<HowItWorks />
<Demo />
<Testimonials />
<Press /> 
 <FAQs /> 
<CTA /> 
<Footer />
*/