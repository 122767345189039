import { useState, useEffect } from 'react';
import WordToggle from '../utils/components/grapes/atoms/wordToggle';

interface VisualToggleProps {
  beforeImage: string;
  afterImage: string;
  width?: string;
}

const VisualToggle = ({ beforeImage, afterImage, width = '600px' }: VisualToggleProps) => {
  const [showAfterImage, setShowAfterImage] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState({ before: false, after: false });
 
  // Add image preloading
  const preloadImages = () => {
    const beforeImg = new Image();
    const afterImg = new Image();

    beforeImg.onload = () => setImagesLoaded(prev => ({ ...prev, before: true }));
    afterImg.onload = () => setImagesLoaded(prev => ({ ...prev, after: true }));

    beforeImg.src = beforeImage;
    afterImg.src = afterImage;
  };

  useEffect(() => {
    preloadImages();
  }, [beforeImage, afterImage]);

  return (
    <div style={{ position: 'relative' }}>
      {/* Main Container with Border */}
      <div
        style={{
          position: 'relative',
          width: width,
          height: `${parseInt(width) * 10.88/16}px`,
          overflow: 'hidden',
          userSelect: 'none',
          border: '2px solid #FFBA31',
          borderRadius: '8px',
          padding: '16px',
          backgroundColor: '#fff'
        }}
      >
        {/* Loading State */}
        {(!imagesLoaded.before || !imagesLoaded.after) && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#666666'
          }}>
            Loading images...
          </div>
        )}

        {/* Images */}
        <img 
          src={showAfterImage ? afterImage : beforeImage}
          alt={showAfterImage ? "After" : "Before"}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: showAfterImage ? (imagesLoaded.after ? 1 : 0) : (imagesLoaded.before ? 1 : 0),
            transition: 'opacity 0.3s ease'
          }}
        />
      </div>
      <div className="flex justify-center mt-[-8px]">
          <WordToggle 
            leftSelected={!showAfterImage}
            leftLabel="Without PathFinder"
            rightLabel="With PathFinder"
            colorOn="pf-daylight"
            colorOff="pf-daylight-T1"
            border="border-2 border-pf-daylight rounded-t-none rounded-b-md"
            onLeft={() => setShowAfterImage(false)}
            onRight={() => setShowAfterImage(true)}
          />
        </div>
    </div>
  );
};

export default VisualToggle;
